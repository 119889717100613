import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';

import {BreadcrumbsNav, ProgressIndicator, ProgressModal, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {contactApi, countyBookApi, reportApi} from '../api';
import {ContactModal, CountyBookCard, CountyBookTable} from '../components';
import {CountyBookRowEntry, CountyEntry} from '../types';
import {ContactFormFields} from '../types/forms';

const CountyBook = () => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, exportingReport: false});
  const [countyBook, setCountyBook] = useState<CountyEntry[]>([]);
  const [renderedItemsIndex, setRenderedItemsIndex] = useState(10);
  const [selectedEntry, setSelectedEntry] = useState<CountyBookRowEntry | undefined>(undefined);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const breadcrumbs = useMemo(() => ([
    {text: 'County Book', active: true}
  ]), []);

  const countyBookToRender = useMemo(() => {
    return countyBook.slice(0, renderedItemsIndex);
  }, [
    renderedItemsIndex,
    countyBook
  ]);

  const handleViewReport = useCallback(async () => {
      try {
        setLoadingState(prevLoadingSate => ({...prevLoadingSate, exportingReport: true}));
        await reportApi.countyBook();
        showSuccessAlert(messages.EXPORT_SUCCESSFUL);
      } catch (error) {
        showErrorAlert(messages.EXPORT_FAILED);
      } finally {
        setLoadingState(prevLoadingSate => ({...prevLoadingSate, exportingReport: false}));
      }
    }, [
      showSuccessAlert,
    showErrorAlert
    ]
  );

  const toggleContactModal = useCallback((countyBookRowEntry?: CountyBookRowEntry) => {
      if (countyBookRowEntry) {
        setSelectedEntry(countyBookRowEntry);
      }
      setContactModalOpen(contactModalOpen => !contactModalOpen);
    }, []
  );

  const saveContact = useCallback(async (values: ContactFormFields, formikHelpers: FormikHelpers<ContactFormFields>) => {
      try {
        const countyEntryIndex = countyBook.findIndex(countyEntry => countyEntry.localUnitEntries.findIndex(localUnitEntry => localUnitEntry.id === values.unitId) !== -1);
        const contact = values.cbContactId === -1 ? await contactApi.create(values) : await contactApi.update(values.cbContactId, values);
        const updatedCountyBook = countyBook.map((countyEntry, index) => {
          return index === countyEntryIndex ?
            {
              ...countyEntry,
              localUnitEntries: countyEntry.localUnitEntries.map(
                localUnityEntry => localUnityEntry.id === values.unitId ? {
                    ...localUnityEntry,
                    manager: contact
                  }
                  :
                  localUnityEntry
              )
            }
            : countyEntry;
        });
        setCountyBook(updatedCountyBook);
        showSuccessAlert(messages.USER_UPDATE_SUCCESSFUL);
      } catch (error) {
        showErrorAlert(messages.USER_UPDATE_FAILED);
      } finally {
        formikHelpers.resetForm();
        formikHelpers.setSubmitting(false);
        setContactModalOpen(false);
      }
    }, [
      countyBook,
      showSuccessAlert,
      showErrorAlert
    ]
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        const countyBook = await countyBookApi.findAll();
        setCountyBook(countyBook);
        setLoadingState(prevLoadingSate => ({...prevLoadingSate, loading: false}));
      } catch (error) {
        setLoadingState(prevLoadingSate => ({...prevLoadingSate, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
      }
    };

    void loadData();
  }, [
    showErrorAlert
  ]);

  // Use a 100ms timeout to render the county book tables in sets of 2
  // This allows the first several tables to render immediately, with the rest delayed.
  useEffect(() => {
    if (renderedItemsIndex < countyBook.length) {
      const timeout = setTimeout(() => setRenderedItemsIndex(renderedItemsIndex + 10), 100);
      return () => clearTimeout(timeout);
    }
  }, [
    renderedItemsIndex,
    countyBook
  ]);

  const renderCountyEntry = useMemo(() => (countyEntry: CountyEntry) => {
    return (
      <CountyBookCard key={countyEntry.id}
                      countyName={countyEntry.name}
                      countyLevel={countyEntry.level}>
        <CountyBookTable countyEntry={countyEntry}
                         onEdit={toggleContactModal}/>
      </CountyBookCard>
    );
  }, [
    toggleContactModal
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid>
        <Row className="mb-4">
          <Col md="6">
            <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
          </Col>
          <Col md="6" className="d-flex justify-content-end">
            <Button color="primary"
                    onClick={handleViewReport}
                    disabled={loadingState.loading || loadingState.loadError}>
              View Report
            </Button>
          </Col>
        </Row>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          {countyBookToRender.map(renderCountyEntry)}
          <ProgressModal isOpen={loadingState.exportingReport}
                         title="Generating County Book Report"
                         content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
          <ContactModal isOpen={contactModalOpen}
                        entry={selectedEntry}
                        onToggle={toggleContactModal}
                        onSave={saveContact}/>
        </>
        }
      </Container>
    );
  }
};

export default CountyBook;