import {memo, useEffect, useMemo, useState} from 'react';

import {ButtonIcon, CustomTable, MiSuiteRole, useUserContext} from '@reasoncorp/kyber-js';

import {Contact, CountyBookRowEntry, CountyEntry} from '../types';
import config from '../config';

const flattenCountyEntry = (countyEntry: CountyEntry): CountyBookRowEntry[] => {
  // Flatten out entry for easier rendering
  const initialEntries: CountyBookRowEntry[] = [{
    unit: {
      id: countyEntry.id,
      name: countyEntry.name,
      type: countyEntry.type,
      level: countyEntry.level
    },
    contact: countyEntry.equalizationDirector
  }];

  countyEntry.localUnitEntries.forEach(localUnitEntry => {
    const unit = {
      id: localUnitEntry.id,
      name: localUnitEntry.name,
      type: localUnitEntry.type,
      level: localUnitEntry.level
    };
    initialEntries.push(
      {unit, contact: localUnitEntry.manager},
      {unit, contact: localUnitEntry.assessorOfRecord}
    );
  });

  return initialEntries;
};

type Props = {
  countyEntry: CountyEntry,
  onEdit: (countyBookRowEntry: CountyBookRowEntry) => void
}

const CountyBookTable = ({
                           countyEntry,
                           onEdit
                         }: Props) => {
  const {permissions} = useUserContext();
  const [entries, setEntries] = useState<CountyBookRowEntry[]>([]);

  const renderNameCell = useMemo(() => (contact: Contact) => {
    if (permissions.isSsoAdmin && contact.ssoUserId) {
      return (
        <td className="align-middle text-nowrap">
          <a href={`${config.sso.webUrl}/users/${contact.ssoUserId}`}
             target="_blank"
             rel="noopener noreferrer">
            {contact.fullName}
          </a>
        </td>
      );
    } else {
      return (
        <td className="align-middle text-nowrap">{contact.fullName}</td>
      );
    }
  }, [
    permissions
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Unit', className: 'text-nowrap'},
      {title: 'Role', className: 'text-nowrap'},
      {title: 'Name', className: 'text-nowrap'},
      {title: 'Level', className: 'text-center'},
      {title: 'Certification #', className: 'text-center text-nowrap'},
      {title: 'Email', className: 'text-nowrap'},
      {title: 'Address', className: 'text-nowrap'},
      {title: 'Phone', className: 'text-center text-nowrap text'},
      {title: 'Edit', className: 'text-nowrap text-center'}
    ],
    items: entries,
    renderRow: (entry: CountyBookRowEntry) => {
      return (
        <tr key={`${entry.unit.id}_${entry.contact.role}`}>
          <td className="align-middle text-nowrap">{entry.unit.name} {entry.unit.type}</td>
          <td className="align-middle text-nowrap">{entry.contact.roleDisplay}</td>
          {renderNameCell(entry.contact)}
          <td className="align-middle text-center text-nowrap">{entry.contact.certificationLevel}</td>
          <td className="align-middle text-center text-nowrap">{entry.contact.certificationNumber}</td>
          <td className="align-middle text-nowrap">{entry.contact.email}</td>
          <td className="align-middle text-nowrap">{entry.contact.address?.addressDisplay}</td>
          <td className="align-middle text-center text-nowrap">{entry.contact.phoneNumber}</td>
          <td className="align-middle text-center edit-cell">
            {(entry.contact.role === MiSuiteRole.TOWNSHIP_SUPERVISOR || entry.contact.role === MiSuiteRole.CITY_MANAGER || entry.contact.role === MiSuiteRole.MAYOR) &&
            <ButtonIcon icon="cog"
                        title={`Edit ${entry.unit.name} ${entry.unit.type} ${entry.contact.roleDisplay} Contact`}
                        ariaLabel={`Edit ${entry.unit.name} ${entry.unit.type} ${entry.contact.roleDisplay} Contact`}
                        className="text-primary"
                        onClick={() => onEdit(entry)}/>
            }
          </td>
        </tr>
      );
    }
  }), [
    entries,
    renderNameCell,
    onEdit
  ]);

  useEffect(() => setEntries(flattenCountyEntry(countyEntry)),
    [countyEntry]
  );

  return (
    <CustomTable {...tableProps}/>
  );
};


export default memo(CountyBookTable);