import {memo, useCallback, useMemo} from 'react';

import {AppBar, SOMLogo, SsoNotificationButton, User, UserProfile} from '@reasoncorp/kyber-js';

import config from '../config';
import {ssoApi} from '../api';

type Props = {
  currentUser?: User
}

const CountyBookAppBar = ({
                            currentUser
                          }: Props) => {

  const handleProfileClick = useCallback(() => {
    window.location.href = `${config.sso.webUrl}/user-profile`;
  }, []);

  const handleNotificationsClick = useCallback(() => {
    window.location.href = `${config.sso.webUrl}/notifications`;
  },[]);

  const handleSignOut = useCallback(async () => {
    await ssoApi.signOut();
  },[]);

  const routes = useMemo(() => ([
    {name: 'County Book', to: '/county-book'},
    {name: 'Municipals', to: '/municipals'}
  ]), []);

  const renderNotificationButton = useMemo(() => {
    return currentUser ? () =>
      <SsoNotificationButton onNotificationsClick={handleNotificationsClick}
                             ssoApi={ssoApi}/> : undefined;
  }, [
    currentUser,
    handleNotificationsClick
  ]);

  const renderUserProfile = useMemo(() => {
    return currentUser ? () =>
      <UserProfile handleProfileClick={handleProfileClick}
                   handleSignOut={handleSignOut}/> : undefined;
  }, [
    handleProfileClick,
    handleSignOut,
    currentUser
  ]);

  return (
    <AppBar brandLink={`${config.sso.webUrl}/dashboard`}
            brandImage={SOMLogo}
            brandImageAlt="MiSuite"
            appName="Michigan County Book"
            organizationName="The Department of Treasury"
            environmentName={config.envName !== 'prod' ? config.envName : undefined}
            routes={routes}
            renderNotificationButton={renderNotificationButton}
            renderUserProfile={renderUserProfile}/>
  );
};

export default memo(CountyBookAppBar);
