import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';

import {BreadcrumbsNav, ProgressIndicator, ProgressModal, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {municipalApi, reportApi} from '../api';
import {CountyBookCard, MunicipalModal, MunicipalTable} from '../components';
import {Municipal, MunicipalEntry} from '../types';
import {MunicipalFormFields} from '../types/forms';

const Municipals = () => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, exportingReport: false});
  const [municipalEntries, setMunicipalEntries] = useState<MunicipalEntry[]>([]);
  const [renderedItemsIndex, setRenderedItemsIndex] = useState(10);
  const municipalsToRender = municipalEntries.slice(0, renderedItemsIndex);
  const [selectedEntry, setSelectedEntry] = useState<Municipal | undefined>(undefined);
  const [municipalModalOpen, setMunicipalModalOpen] = useState(false);

  const breadcrumbs = useMemo(() => ([
    {text: 'Municipals', active: true}
  ]), []);

  const handleViewReport = useCallback(async () => {
      try {
        setLoadingState(prevLoadingSate => ({...prevLoadingSate, exportingReport: true}));
        await reportApi.municipals();
        showSuccessAlert(messages.EXPORT_SUCCESSFUL);
      } catch (error) {
        showErrorAlert(messages.EXPORT_FAILED);
      } finally {
        setLoadingState(prevLoadingSate => ({...prevLoadingSate, exportingReport: false}));
      }
    }, [
      showSuccessAlert,
    showErrorAlert
    ]
  );

  const toggleMunicipalModal = useCallback((municipal?: Municipal) => {
      if (municipal) {
        setSelectedEntry(municipal);
      }
      setMunicipalModalOpen(municipalModalOpen => !municipalModalOpen);
    }, []
  );

  const saveMunicipal = useCallback(async (values: MunicipalFormFields, formikHelpers: FormikHelpers<MunicipalFormFields>) => {
      if (selectedEntry) {
        try {
          const updatedMunicipal = await municipalApi.update(selectedEntry.id, values);
          const municipalEntryIndex = municipalEntries.findIndex(municipalEntry => municipalEntry.municipals.findIndex(municipal => municipal.id === updatedMunicipal.id) !== -1);
          const updatedMunicipalEntries = municipalEntries.map((municipalEntry, index) => {
            return index === municipalEntryIndex ? {
                ...municipalEntry,
                municipals: municipalEntry.municipals.map(
                  municipal => municipal.id === updatedMunicipal.id ? updatedMunicipal : municipal
                )
              }
              : municipalEntry;
          });
          setMunicipalEntries(updatedMunicipalEntries);
          showSuccessAlert(messages.MUNICIPAL_UPDATE_SUCCESSFUL);
        } catch (error) {
          showErrorAlert(messages.MUNICIPAL_UPDATE_FAILED);
        } finally {
          formikHelpers.resetForm();
          formikHelpers.setSubmitting(false);
          setMunicipalModalOpen(false);
        }
      }
    },
    [
      municipalEntries,
      selectedEntry,
      showSuccessAlert,
      showErrorAlert
    ]
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        const municipals = await municipalApi.findAll();
        setMunicipalEntries(municipals);
        setLoadingState(prevLoadingSate => ({...prevLoadingSate, loading: false}));
      } catch (error) {
        setLoadingState(prevLoadingSate => ({...prevLoadingSate, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
      }
    };

    loadData().then();
  }, [showErrorAlert]);

  // Use a 100ms timeout to render the county book tables in sets of 2
  // This allows the first several tables to render immediately, with the rest delayed.
  useEffect(() => {
    if (renderedItemsIndex < municipalEntries.length) {
      const timeout = setTimeout(() => setRenderedItemsIndex(renderedItemsIndex + 10), 100);
      return () => clearTimeout(timeout);
    }
  }, [
    renderedItemsIndex,
    municipalEntries
  ]);

  const renderMunicipalEntry = useMemo(() => (municipalEntry: MunicipalEntry) => {
    return <CountyBookCard key={municipalEntry.id}
                           countyName={municipalEntry.name}
                           countyLevel={municipalEntry.level}>
      <MunicipalTable municipals={municipalEntry.municipals}
                      onEdit={toggleMunicipalModal}/>
    </CountyBookCard>;
  }, [
    toggleMunicipalModal
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid>
        <Row className="mb-4">
          <Col md="6">
            <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
          </Col>
          <Col md="6" className="d-flex justify-content-end">
            <Button color="primary"
                    onClick={handleViewReport}
                    disabled={loadingState.loading || loadingState.loadError}>
              View Report
            </Button>
          </Col>
        </Row>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          {municipalsToRender.map(renderMunicipalEntry)}
          <ProgressModal isOpen={loadingState.exportingReport}
                         title="Generating Municipal Report"
                         content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
          <MunicipalModal isOpen={municipalModalOpen}
                          municipal={selectedEntry}
                          onToggle={toggleMunicipalModal}
                          onSave={saveMunicipal}/>
        </>
        }
      </Container>
    );
  }
};

export default Municipals;